<template>
    <div class="app-container">
        <div class="repeatTop">
            <div class="box">
                <div class="left">
                    <el-dropdown @command="getDropdown">
                        <span class="el-dropdown-link">
                            按{{ searchName }}名称<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item divided v-for="(item, index) in searchDataType" :key="index"
                                :command="item">{{ item.name }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="right">
                    <input type="search" name="" id="searchInput" autocomplete="off">
                </div>
            </div>
            <div class="btn_search">
                <el-button type="primary" icon="el-icon-search" @click="searchBtn()">搜索</el-button>
                <el-button @click="getList()"> 刷新 </el-button>
            </div>
        </div>
        <el-table ref="singleTable" :data="tableData" @current-change="handleTableChange" border size="24"
            v-loading="loading" fit highlight-current-row stripe @row-click="handleRowClick">
            <el-table-column type="index" label="序号" width="95" align="center">
            </el-table-column>
            <el-table-column property="enterpriseName" label="客户姓名">
            </el-table-column>
            <el-table-column property="contacts" label="联系人">
            </el-table-column>
            <el-table-column property="contactNumber" label="联系电话">
            </el-table-column>
            <!-- <el-table-column property="status" label="模块">
            </el-table-column> -->
            <el-table-column label="模块">
                <template slot-scope="scope">
                    <span class="styleModel" @click="handleTColumn(scope.row)">{{ scope.row.status }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination layout="prev, pager, next" :total="customerDataObj.total"
                @current-change="handleCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import {
    getRepeatList
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
export default {
    data() {
        return {
            loading: true,
            tableData: [],
            currentRow: null,
            searchDataType: [
                {
                    type: 1,
                    name: '客户'
                },
                {
                    type: 2,
                    name: '电话'
                },
                {
                    type: 3,
                    name: '联系人'
                }
            ],
            searchName: '客户',
            customerDataObj: {
                total: 0,
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    type: 1, // （企业名称：1，电话：2，联系人：3）
                    queryCondition: ''
                }
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleTableChange(val) {
            console.log(val, 'val');
            this.currentRow = val;

        },
        handleRowClick(row) {
            console.log(row);
        },
        handleTColumn(e) {
            console.log(e, '数据');
            if (e.status == '公海') {
                this.$router.push({
                    path: "/gsPark/customerRelaManagement/highseas/highseasView",
                    query: { id: e.id },
                });
            } else if(e.status == '客户') {
                this.$router.push({
                    path: "/gsPark/customerRelaManagement/customerList/customerListDetail",
                    query: { id: e.id },
                });
            } else if(e.status == '线索') {
                this.$router.push({
                    path: "/gsPark/customerRelaManagement/customerLeads/customerLeadsView",
                    query: { id: e.id },
                });
            }
        },
        getList() {
            this.loading = true
            getRepeatList(this.customerDataObj.listQuery).then(res => {
                console.log(res);
                if (res) {
                    this.loading = false
                    res.data.rows.forEach((item) => {
                        if (item.status == 0) {
                            item.status = '公海'
                        } else if (item.status == 1) {
                            item.status = '客户'
                        } else if (item.status == 2) {
                            item.status = '线索'
                        }
                    })
                    this.tableData = res.data.rows
                    console.log(this.tableData, 'this.tableData');
                    this.customerDataObj.total = res.data.total
                }
                console.log(this.customerDataObj.total, ' this.customerDataObj.total');
            })
        },
        getDropdown(e) {
            console.log(e);
            this.searchName = e.name
            this.customerDataObj.listQuery.type = e.type
            this.getList()
        },
        searchBtn() {
            const inputElement = document.getElementById('searchInput');
            const enteredValue = inputElement.value;
            this.customerDataObj.listQuery.queryCondition = enteredValue
            // console.log('搜索:', enteredValue);
            // console.log('条件', this.customerDataObj);
            this.customerDataObj.listQuery.current = 1
            this.getList()
        },
        handleCurrentChange(val) {
            console.log(val, '分页');
            this.customerDataObj.listQuery.current = val
            this.getList()
        }
    }
}

</script>
<style lang="less" scoped>
input[type="search"] {
    border: none;
    outline: none;
}
.styleModel {
    cursor: pointer;
    color: #0F2FD5;
}
.repeatTop {
    display: flex;

    .box {
        width: 800px;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        display: flex;
        margin-bottom: 28px;

        .left {
            margin-left: 16px;
        }

        .right {
            margin-left: 16px;
            padding-left: 16px;
            border-left: 1px solid #DCDFE6;

            input {
                width: 640px;
                border: none;
            }
        }
    }

    .btn_search {
        margin-left: 24px;
        margin-top: 3px;
    }
}
</style>